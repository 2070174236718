$(document).ready(function(){
	$(document).foundation();
var myLocation = "../../php/";
// 1. Off-canvas full height
	function SetOffCanvasHeight() {
		var height = $(window).height();
		var contentHeight = $(".off-canvas-content").height();
		if (contentHeight > height) { height = contentHeight; }

		$(".off-canvas-wrapper").height(height);
		$(".off-canvas-wrapper-inner").height(height);
		$(".off-canvas").height(height);
	} 

	$(window)
	.on('load',	function() {
		SetOffCanvasHeight();
	})
	.resize(function() {
		SetOffCanvasHeight();
	});

	// Off-canvas close when an item of the menu is clicked
	$('.off-canvas a').on('click', function() {
		$('.off-canvas').foundation('close');
	});
// 2. No point 2 yet


/*
// 3. If the page is called with a sub-section (main-* div), we need to show that div
	// so we define a function to get the hash (#) component of the navigation
	function gethash() {
		// the full URL is in window.location
		var loc = window.location;
		// substring the URL to get everything after the last slash (/)
		// .pathname method returns a string that does not include the hostname but everything including the filename
		// console.log(loc.pathname);
		// pathName (with upper case N) will strip the filename from the path
		// var pathName = loc.pathname.substring(0, loc.pathname.lastIndexOf('/') + 1);
		// console.log(pathName);
		var hashparm = loc.hash;
		if (hashparm === '') {
//console.log('setting #main-1 as default');
			hashparm = '#main-1';
			}
		return hashparm;
//    	return loc.href.substring(0, loc.href.length - ((loc.pathname + loc.search + loc.hash).length - pathName.length));
	}

	// Now show the appropriate div when the menu item is clicked and hide all the other in the set
	// first hide all the "main*" divs except the first one
	// $('div[id^="main"]').not('#main-1').addClass('hide'); // no longer needed because we can use the gethash function
	//
	// Define a function to reveal the selected div and hide the others
	function showMeOnly(myID,f){
//		$('div[id^="main"]').not(myID + ', .hide').addClass('hide');
		$('div[id^="main"]').not(myID).addClass('hide');
		$(myID).removeClass('hide');
//		work through the menu buttons and highlight my button

		$('.item').each(
			function(){
				if ($(this).attr('href') === myID) {
					$(this).addClass('active');
				}else{
					$(this).removeClass('active');
				}
			}
		);

		if(f !== undefined){f(myID);return false;}
		else{
//console.log('myID is ' + myID);
			$('body').animate({scrollTop:0},'slow');
			return false;
			}
	}
	
	// when page is first loaded, we need to only show the div that corresponds to the hash value in the URL
	// gethash function (above) will return the hash menu item or if the URL doesn't include hash, the function
	// will return '#main-1'
	var myDiv = gethash();
	showMeOnly(myDiv);

	
	// set the top-bar menu a elements to call the showMeOnly function based on its href attribute
	// then set the sub-menu a elements to call the function based on its href attribute
	// ...main menu:
	// deals with menu clicks for the current page only!
	$('a[href^="#main"]').on('click',
		function(){
			var myID = $(this).attr('href');
			showMeOnly(myID);
			$('body').animate({scrollTop:0},'slow');
//console.log('click: scrolling to ' + myID);
			return false;
		});
	// ...sub-menu:
*/

/*
	$('.item, .menuoffcanvas').bind('click',
		function(){
			var myID = $(this).attr('href');
			showMeOnly(myID);
			if ($(this).hasClass('menuoffcanvas') ){
				$('a.right-off-canvas-toggle').trigger('click');
			}
//			window.location(myID);
			return true;
		}
	);
*/


// 4. Contact Form

	// define the regular expression		
//	var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
	var emailPattern = /^[-a-zA-Z0-9\_\+]+(\.[-a-zA-Z0-9\_\+\']+)*\@[-a-zA-Z0-9]+(\.[a-zA-Z]{1,}){1,4}$/;
	// for the response messages, which are foundation alerts, hide them first
	$('#myDivAlert,#myDivSuccess').slideUp('fast');
	$('a.close').off('click').on('click',
		function(){
			$(this).parent().slideUp('fast');
			return false;
		});
	// for the email and message divs, bind key stroke events so that any input gets validated
	$('#email,#message').on('keyup',
		function(){
			// make sure the email pattern is valid and that the message contains something
			if( emailPattern.test( $('#email').val() ) && $('#message').val() !== ''
			){
				// and bind the button event
			$('#contactSubmit').off('click').on('click',
				function(){
					$.post(myLocation + 'email_form.php',{
						email:		encodeURI( $('#email').val() ),
						message:	encodeURI( $('#message').val() ),
						name:		encodeURI( $('#name').val() ),
						phone:		encodeURI( $('#phone').val() )
						},					
							function(data){
								var msg = ($('#error', data).text());
								if (msg !== ''){
									$('#errorMessage').text(msg);
									$('#myDivAlert').slideDown('slow');
								}else{
									$('#name, #phone, #email, #message').val('');
									msg = $('#msg', data).text();
									$('#successMessage').text(msg);
									$('#myDivSuccess').slideDown('slow');
								}
							
					});
			return false;
			});
			}else{
				$('#contactSubmit').off('click').on('click',
					function(){
						$('#errorMessage').text("Sorry, something is wrong. Please check the required fields!");
						$('#myDivAlert').slideDown('slow');
						return false;
					});
			}
		}).trigger('keyup');

// 5. Turn off the JavaScript warning
	$('#javaWarn').text('');
	
// 6. Close modal form on submit
	$('#mc-embedded-subscribe').on('click',
		function(){
			$('#myModal').foundation('reveal', 'close');
		}
	);
	


// end of the document ready function
});
